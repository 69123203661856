@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

$ubuntu-font: "Ubuntu", sans-serif;

$bg: rgb(255, 255, 255);
$bg2: rgb(0, 0, 0);
$text: rgb(15, 15, 15);
$text2: rgb(240, 240, 240);
$primary: rgb(190, 113, 214);

$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 32px;

$header-height: 72px;

$sm: 37.5em;
$md: 48em;
$lg: 64em;
$xl: 75em;

// @mixin breakpoint($point) {
//   @if $point == md {
//     @media (max-with: $md) {
//       @content;
//     }
//   }
// }

$breakpoints: (
  "sm": "36em",
  "md": "48em",
  "lg": "62em",
  "xlg": "75em",
  "xxlg": "87.5em",
);

@mixin breakpoint($size) {
  @media (min-width: map-get($breakpoints, $size)) {
    @content;
  }
}