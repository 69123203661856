@import "./styles/variables.scss";

/* Global */

html {
    scroll-behavior: smooth;
    scroll-padding: 72px;
}

body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMaxSystemFont, Segoe UI, Roboto, Oxygen,
        Unbuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    height: 100%;
    color: #fff;
    background-color: #fff;
}

.main {
    min-height: calc(100% - 420px);
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

button {
    // reset to default
    font-family: inherit;
    outline: none;
    border: none;
    background: none;
    letter-spacing: inherit;
    color: inherit;
    font-size: inherit;
    text-align: inherit;
    padding: 0;
}

// Cards
.card {
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 0px 50px rgba(190, 113, 214, 0.4);
}

.card h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: black;
}

.card p {
    font-size: 16px;
    color: #1d1d1d;
}

.card h3 {
    font-size: 28px;
    color: $primary;
    margin-top: 10px;
}

// Cards
// Footer

.section__padding {
    padding: 4rem 4rem;
}

.footer {
    margin: auto;
    background-color: #1d1d1d;
    position: relative;
    bottom: 0;
    width: 100%;
}

.sb__footer {
    display: flex;
    flex-direction: column;
}

.sb__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}

.sb__footer-links_div {
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: #fff;
}

a {
    color: rgb(175, 175, 179);
    text-decoration: none;
}

.socialmedia {
    display: flex;
    flex-direction: row;
}

.sb__footer-links_div h4 {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
}

.sb__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
}

.sb__footer-below {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0.2rem;
}

.sb__footer-below-links {
    display: flex;
    flex-direction: row;
}

.sb__footer-below-links p {
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    color: #fff;
    font-weight: 600;
}

hr {
    color: #fff !important;
    width: 100%;
}

.sb__footer-copyright p {
    font-size: 13px;
    line-height: 15px;
    color: #fff;
    font-weight: 600;
}

.big-image {
    width: 50%;
    height: 50%;
}


// Footer

/* Global */

@media only screen and (max-device-width: 480px) {

    // sections
    section {
        scroll-snap-align: start;
        position: relative;
        justify-content: center;
        flex-direction: column;
        display: flex;
    }

    section:nth-child(odd) {
        background-color: #f8f9fa;
        /* Even sections have a different background */
    }

    // Foot
    section:nth-child(1) {
        min-height: 100vh;
        height: 100vh;
        background: $primary;
        background-image: url("../public/bigstomp.gif");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    // about
    section:nth-child(2) {
        height: 250vh;
        min-height: 100vh;
        background: $primary;
        // background-image: url("../public/little-stomper-high-resolution-logo-transparent\ copy.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    // pricing
    section:nth-child(3) {
        height: 230vh;
        min-height: 100vh;
        background: #1d1d1d;
    }

    // booking
    section:nth-child(4) {
        height: 230vh;
        min-height: 100vh;
        background: $primary;
    }

    // map
    section:nth-child(5) {
        height: 400px;
        min-height: 400px;
        background: $primary;
    }

    // sections
    // aligned
    .aligned-center {
        color: #fff;
        padding-top: 50vh;
        padding-bottom: 50vh;
        justify-self: center;
        text-align: center;
        background-color: rgba(29, 29, 29, 0.8);

        h1 {
            font-size: xx-large;
            padding-bottom: 2vh;
        }

        p {
            font-size: x-large;
        }

        button {
            margin: 1vh;
        }
    }

    .aligned-centerS2 {
        color: #fff;
        height: 200vh;
        justify-self: center;
        text-align: center;
        background-color: rgba(29, 29, 29, 0.7);

        h1 {
            font-size: xx-large;
        }

        h2 {
            font-size: xx-large;
            text-decoration: underline #fff;
        }

        p {
            font-size: x-large;
            text-align: center;
            padding: 1vh;
        }
    }

    .aligned-centerS3 {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    // aligned
    // form
    .form-container {
        border-radius: 5px;
        padding: 20px;
        background-color: #1d1d1d;
        text-align: center;
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .form-style {
        align-items: center;
        justify-self: center;

        h1 {
            padding: 2vh;
            font-size: xx-large;
        }

        h2 {
            padding-bottom: 1vh;
        }

        h3 {
            padding-bottom: 1vh;
        }

        div {
            display: block;
        }

    }

    .form-block {
        width: 100%;
        padding-bottom: 2vh;
    }

    .form-item {
        width: 100%;
        padding-bottom: 1vh;

        input {
            min-width: 100%;
            background-color: #1d1d1d;
            color: #fff;
            height: 5vh;
            position: relative;
            border-bottom: 1px solid #fff;
        }


        input:hover {
            border: 3px solid $primary;
        }

        input:focus {
            border: 3px solid $primary;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type="datetime-local"]:after {
            content: "\25BC";
            color: #555;
            padding: 0 5px;
            display: none;
        }

        input[type="datetime-local"]::-webkit-calendar-picker-indicator {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;
            height: auto;
            color: transparent;
            background: transparent;
        }

        input[type="datetime-local"]::-webkit-inner-spin-button {
            z-index: 1;
        }

        input[type="datetime-local"]::-webkit-clear-button {
            z-index: 1;
        }
    }

    input[type="submit"] {
        background: none;
    }

    .form-button {
        width: 100%;
        height: 5vh;
        font-weight: 500;
        color: $primary;
        border: 2px solid $primary;
        padding: $spacing-sm $spacing-lg;
        border-radius: $spacing-sm / 2;
        cursor: pointer;
        background-color: 0 0 0 0.9;

        box-shadow: 0 0 0.5em 0 $primary;

        &:active {
            transform: scale(0.98);
            border-radius: 0;
        }

        &:hover {
            box-shadow: 0 0 0.75em 0 $primary;
        }
    }

    // form
}

/* Extra small devices (phones) */
@media only screen and (max-device-width: 576px) {

    // sections
    section {
        scroll-snap-align: start;
        position: relative;
        justify-content: center;
        flex-direction: column;
        display: flex;
    }

    section:nth-child(odd) {
        background-color: #f8f9fa;
        /* Even sections have a different background */
    }

    // Foot
    section:nth-child(1) {
        min-height: 100vh;
        height: 100vh;
        background: $primary;
        background-image: url("../public/bigstomp.gif");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    // about
    section:nth-child(2) {
        height: 140vh;
        min-height: 100vh;
        background: $primary;
        // background-image: url("../public/little-stomper-high-resolution-logo-transparent\ copy.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    // pricing
    section:nth-child(3) {
        height: 130vh;
        min-height: 100vh;
        background: #1d1d1d;
    }

    // booking
    section:nth-child(4) {
        height: 230vh;
        min-height: 100vh;
        background: $primary;
    }

    // map
    section:nth-child(5) {
        height: 400px;
        min-height: 400px;
        background: $primary;
    }

    // sections
    // aligned
    .aligned-center {
        color: #fff;
        padding-top: 50vh;
        padding-bottom: 50vh;
        justify-self: center;
        text-align: center;
        background-color: rgba(29, 29, 29, 0.8);

        h1 {
            font-size: xx-large;
            padding-bottom: 2vh;
        }

        p {
            font-size: x-large;
        }

        button {
            margin: 1vh;
        }
    }

    .aligned-centerS2 {
        color: #fff;
        height: 140vh;
        justify-self: center;
        text-align: center;
        background-color: rgba(29, 29, 29, 0.7);

        h1 {
            font-size: xx-large;
        }

        h2 {
            font-size: xx-large;
            text-decoration: underline #fff;
        }

        p {
            font-size: x-large;
            text-align: center;
            padding: 1vh;
        }
    }

    .aligned-centerS3 {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    // aligned
    // form
    .form-container {
        border-radius: 5px;
        padding: 20px;
        background-color: #1d1d1d;
        text-align: center;
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .form-style {
        align-items: center;
        justify-self: center;

        h1 {
            padding: 2vh;
            font-size: xx-large;
        }

        h2 {
            padding-bottom: 1vh;
        }

        h3 {
            padding-bottom: 1vh;
        }

        div {
            display: block;
        }

    }

    .form-block {
        width: 100%;
        padding-bottom: 2vh;
    }

    .form-item {
        width: 100%;
        padding-bottom: 1vh;

        input {
            min-width: 100%;
            background-color: #1d1d1d;
            color: #fff;
            height: 5vh;
            position: relative;
            border-bottom: 1px solid #fff;
        }


        input:hover {
            border: 3px solid $primary;
        }

        input:focus {
            border: 3px solid $primary;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type="datetime-local"]:after {
            content: "\25BC";
            color: #555;
            padding: 0 5px;
            display: none;
        }

        input[type="datetime-local"]::-webkit-calendar-picker-indicator {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;
            height: auto;
            color: transparent;
            background: transparent;
        }

        input[type="datetime-local"]::-webkit-inner-spin-button {
            z-index: 1;
        }

        input[type="datetime-local"]::-webkit-clear-button {
            z-index: 1;
        }
    }

    input[type="submit"] {
        background: none;
    }

    .form-button {
        width: 100%;
        height: 5vh;
        font-weight: 500;
        color: $primary;
        border: 2px solid $primary;
        padding: $spacing-sm $spacing-lg;
        border-radius: $spacing-sm / 2;
        cursor: pointer;
        background-color: 0 0 0 0.9;

        box-shadow: 0 0 0.5em 0 $primary;

        &:active {
            transform: scale(0.98);
            border-radius: 0;
        }

        &:hover {
            box-shadow: 0 0 0.75em 0 $primary;
        }
    }

    // form
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (min-width: 576px) {

    // sections
    section {
        scroll-snap-align: start;
        position: relative;
        justify-content: center;
        flex-direction: column;
        display: flex;
    }

    section:nth-child(odd) {
        background-color: #f8f9fa;
        /* Even sections have a different background */
    }

    // Foot
    section:nth-child(1) {
        min-height: 100vh;
        height: 100vh;
        background: $primary;
        background-image: url("../public/bigstomp.gif");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    // about
    section:nth-child(2) {
        height: 80vh;
        min-height: 50vh;
        background: $primary;
        // background-image: url("../public/little-stomper-high-resolution-logo-transparent\ copy.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    // pricing
    section:nth-child(3) {
        height: 100vh;
        min-height: 100vh;
        background: #1d1d1d;
    }

    // booking
    section:nth-child(4) {
        height: 150vh;
        min-height: 200vh;
        background: $primary;
    }

    // map
    section:nth-child(5) {
        height: 400px;
        min-height: 400px;
        background: $primary;
    }

    // sections
    // aligned
    .aligned-center {
        color: #fff;
        padding-top: 50vh;
        padding-bottom: 50vh;
        justify-self: center;
        text-align: center;
        background-color: rgba(29, 29, 29, 0.8);

        h1 {
            font-size: xx-large;
            padding-bottom: 2vh;
        }

        p {
            font-size: x-large;
        }

        button {
            margin: 1vh;
        }
    }

    .aligned-centerS2 {
        color: #fff;
        height: 80vh;
        justify-self: center;
        text-align: center;
        background-color: rgba(29, 29, 29, 0.7);

        h1 {
            font-size: xx-large;
        }

        h2 {
            font-size: xx-large;
            text-decoration: underline #fff;
        }

        p {
            font-size: x-large;
            text-align: center;
            padding: 1vh;
        }
    }

    .aligned-centerS3 {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    // aligned
    // form
    .form-container {
        border-radius: 5px;
        padding: 20px;
        background-color: #1d1d1d;
        text-align: center;
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .form-style {
        align-items: center;
        justify-self: center;

        h1 {
            padding: 2vh;
            font-size: xx-large;
        }

        h2 {
            padding-bottom: 1vh;
        }

        h3 {
            padding-bottom: 1vh;
        }

        div {
            display: block;
        }

    }

    .form-block {
        width: 100%;
        padding-bottom: 2vh;
    }

    .form-item {
        width: 100%;
        padding-bottom: 1vh;

        input {
            min-width: 100%;
            background-color: #1d1d1d;
            color: #fff;
            height: 5vh;
            position: relative;
            border-bottom: 1px solid #fff;
        }


        input:hover {
            border: 3px solid $primary;
        }

        input:focus {
            border: 3px solid $primary;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type="datetime-local"]:after {
            content: "\25BC";
            color: #555;
            padding: 0 5px;
            display: none;
        }

        input[type="datetime-local"]::-webkit-calendar-picker-indicator {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;
            height: auto;
            color: transparent;
            background: transparent;
        }

        input[type="datetime-local"]::-webkit-inner-spin-button {
            z-index: 1;
        }

        input[type="datetime-local"]::-webkit-clear-button {
            z-index: 1;
        }
    }

    input[type="submit"] {
        background: none;
    }

    .form-button {
        width: 100%;
        height: 5vh;
        font-weight: 500;
        color: $primary;
        border: 2px solid $primary;
        padding: $spacing-sm $spacing-lg;
        border-radius: $spacing-sm / 2;
        cursor: pointer;
        background-color: 0 0 0 0.9;

        box-shadow: 0 0 0.5em 0 $primary;

        &:active {
            transform: scale(0.98);
            border-radius: 0;
        }

        &:hover {
            box-shadow: 0 0 0.75em 0 $primary;
        }
    }

    // form
}

/* Medium devices (landscape tablets) */
@media only screen and (min-width: 768px) {

    // sections
    section {
        scroll-snap-align: start;
        position: relative;
        justify-content: center;
        flex-direction: column;
        display: flex;
    }

    section:nth-child(odd) {
        background-color: #f8f9fa;
        /* Even sections have a different background */
    }

    // Foot
    section:nth-child(1) {
        min-height: 100vh;
        height: 100vh;
        background: $primary;
        background-image: url("../public/bigstomp.gif");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    // about
    section:nth-child(2) {
        height: 70vh;
        min-height: 50vh;
        background: $primary;
        // background-image: url("../public/little-stomper-high-resolution-logo-transparent\ copy.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    // pricing
    section:nth-child(3) {
        height: 100vh;
        min-height: 100vh;
        background: #1d1d1d;
    }

    // booking
    section:nth-child(4) {
        height: 150vh;
        min-height: 200vh;
        background: $primary;
    }

    // map
    section:nth-child(5) {
        height: 400px;
        min-height: 400px;
        background: $primary;
    }

    // sections
    // aligned
    .aligned-center {
        color: #fff;
        padding-top: 50vh;
        padding-bottom: 50vh;
        justify-self: center;
        text-align: center;
        background-color: rgba(29, 29, 29, 0.8);

        h1 {
            font-size: xx-large;
            padding-bottom: 2vh;
        }

        p {
            font-size: x-large;
        }

        button {
            margin: 1vh;
        }
    }

    .aligned-centerS2 {
        color: #fff;
        height: 70vh;
        justify-self: center;
        text-align: center;
        background-color: rgba(29, 29, 29, 0.7);

        h1 {
            font-size: xx-large;
        }

        h2 {
            font-size: xx-large;
            text-decoration: underline #fff;
        }

        p {
            font-size: x-large;
            text-align: center;
            padding: 1vh;
        }
    }

    .aligned-centerS3 {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    // aligned
    // form
    .form-container {
        border-radius: 5px;
        padding: 20px;
        background-color: #1d1d1d;
        text-align: center;
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .form-style {
        align-items: center;
        justify-self: center;

        h1 {
            padding: 2vh;
            font-size: xx-large;
        }

        h2 {
            padding-bottom: 1vh;
        }

        h3 {
            padding-bottom: 1vh;
        }

        div {
            display: block;
        }

    }

    .form-block {
        width: 100%;
        padding-bottom: 2vh;
    }

    .form-item {
        width: 100%;
        padding-bottom: 1vh;

        input {
            min-width: 100%;
            background-color: #1d1d1d;
            color: #fff;
            height: 5vh;
            position: relative;
            border-bottom: 1px solid #fff;
        }


        input:hover {
            border: 3px solid $primary;
        }

        input:focus {
            border: 3px solid $primary;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type="datetime-local"]:after {
            content: "\25BC";
            color: #555;
            padding: 0 5px;
            display: none;
        }

        input[type="datetime-local"]::-webkit-calendar-picker-indicator {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;
            height: auto;
            color: transparent;
            background: transparent;
        }

        input[type="datetime-local"]::-webkit-inner-spin-button {
            z-index: 1;
        }

        input[type="datetime-local"]::-webkit-clear-button {
            z-index: 1;
        }
    }

    input[type="submit"] {
        background: none;
    }

    .form-button {
        width: 100%;
        height: 5vh;
        font-weight: 500;
        color: $primary;
        border: 2px solid $primary;
        padding: $spacing-sm $spacing-lg;
        border-radius: $spacing-sm / 2;
        cursor: pointer;
        background-color: 0 0 0 0.9;

        box-shadow: 0 0 0.5em 0 $primary;

        &:active {
            transform: scale(0.98);
            border-radius: 0;
        }

        &:hover {
            box-shadow: 0 0 0.75em 0 $primary;
        }
    }

    // form
}

/* Large devices (laptops/desktops) */
@media only screen and (min-width: 992px) {

    // sections
    section {
        scroll-snap-align: start;
        position: relative;
        justify-content: center;
        flex-direction: column;
        display: flex;
    }

    section:nth-child(odd) {
        background-color: #f8f9fa;
        /* Even sections have a different background */
    }

    // Foot
    section:nth-child(1) {
        min-height: 100vh;
        height: 100vh;
        background: $primary;
        background-image: url("../public/bigstomp.gif");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    // about
    section:nth-child(2) {
        height: 60vh;
        min-height: 50vh;
        background: $primary;
        // background-image: url("../public/little-stomper-high-resolution-logo-transparent\ copy.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    // pricing
    section:nth-child(3) {
        height: 100vh;
        min-height: 100vh;
        background: #1d1d1d;
    }

    // booking
    section:nth-child(4) {
        height: 150vh;
        min-height: 200vh;
        background: $primary;
    }

    // map
    section:nth-child(5) {
        height: 400px;
        min-height: 400px;
        background: $primary;
    }

    // sections
    // aligned
    .aligned-center {
        color: #fff;
        padding-top: 50vh;
        padding-bottom: 50vh;
        justify-self: center;
        text-align: center;
        background-color: rgba(29, 29, 29, 0.8);

        h1 {
            font-size: xx-large;
            padding-bottom: 2vh;
        }

        p {
            font-size: x-large;
        }

        button {
            margin: 1vh;
        }
    }

    .aligned-centerS2 {
        color: #fff;
        height: 60vh;
        justify-self: center;
        text-align: center;
        background-color: rgba(29, 29, 29, 0.7);

        h1 {
            font-size: xx-large;
        }

        h2 {
            font-size: xx-large;
            text-decoration: underline #fff;
        }

        p {
            font-size: x-large;
            text-align: center;
            padding: 1vh;
        }
    }

    .aligned-centerS3 {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    // aligned
    // form
    .form-container {
        border-radius: 5px;
        padding: 20px;
        background-color: #1d1d1d;
        text-align: center;
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .form-style {
        align-items: center;
        justify-self: center;

        h1 {
            padding: 2vh;
            font-size: xx-large;
        }

        h2 {
            padding-bottom: 1vh;
        }

        h3 {
            padding-bottom: 1vh;
        }

        div {
            display: block;
        }

    }

    .form-block {
        width: 100%;
        padding-bottom: 2vh;
    }

    .form-item {
        width: 100%;
        padding-bottom: 1vh;

        input {
            min-width: 100%;
            background-color: #1d1d1d;
            color: #fff;
            height: 5vh;
            position: relative;
            border-bottom: 1px solid #fff;
        }


        input:hover {
            border: 3px solid $primary;
        }

        input:focus {
            border: 3px solid $primary;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type="datetime-local"]:after {
            content: "\25BC";
            color: #555;
            padding: 0 5px;
            display: none;
        }

        input[type="datetime-local"]::-webkit-calendar-picker-indicator {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;
            height: auto;
            color: transparent;
            background: transparent;
        }

        input[type="datetime-local"]::-webkit-inner-spin-button {
            z-index: 1;
        }

        input[type="datetime-local"]::-webkit-clear-button {
            z-index: 1;
        }
    }

    input[type="submit"] {
        background: none;
    }

    .form-button {
        width: 100%;
        height: 5vh;
        font-weight: 500;
        color: $primary;
        border: 2px solid $primary;
        padding: $spacing-sm $spacing-lg;
        border-radius: $spacing-sm / 2;
        cursor: pointer;
        background-color: 0 0 0 0.9;

        box-shadow: 0 0 0.5em 0 $primary;

        &:active {
            transform: scale(0.98);
            border-radius: 0;
        }

        &:hover {
            box-shadow: 0 0 0.75em 0 $primary;
        }
    }

    // form
}

/* Extra large devices (large desktops) */
@media only screen and (min-width: 1200px) {

    // sections
    section {
        scroll-snap-align: start;
        position: relative;
        justify-content: center;
        flex-direction: column;
        display: flex;
    }

    section:nth-child(odd) {
        background-color: #f8f9fa;
        /* Even sections have a different background */
    }

    // Foot
    section:nth-child(1) {
        min-height: 100vh;
        height: 100vh;
        background: $primary;
        background-image: url("../public/bigstomp.gif");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    // about
    section:nth-child(2) {
        height: 60vh;
        min-height: 50vh;
        background: $primary;
        // background-image: url("../public/little-stomper-high-resolution-logo-transparent\ copy.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    // pricing
    section:nth-child(3) {
        height: 100vh;
        min-height: 100vh;
        background: #1d1d1d;
    }

    // booking
    section:nth-child(4) {
        height: 150vh;
        min-height: 200vh;
        background: $primary;
    }

    // map
    section:nth-child(5) {
        height: 400px;
        min-height: 400px;
        background: $primary;
    }

    // sections
    // aligned
    .aligned-center {
        color: #fff;
        padding-top: 50vh;
        padding-bottom: 50vh;
        justify-self: center;
        text-align: center;
        background-color: rgba(29, 29, 29, 0.8);

        h1 {
            font-size: xx-large;
            padding-bottom: 2vh;
        }

        p {
            font-size: x-large;
        }

        button {
            margin: 1vh;
        }
    }

    .aligned-centerS2 {
        color: #fff;
        height: 100vh;
        justify-self: center;
        text-align: center;
        background-color: rgba(29, 29, 29, 0.7);

        h1 {
            font-size: xx-large;
        }

        h2 {
            font-size: xx-large;
            text-decoration: underline #fff;
        }

        p {
            font-size: x-large;
            text-align: center;
            padding: 1vh;
        }
    }

    .aligned-centerS3 {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    // aligned
    // form
    .form-container {
        border-radius: 5px;
        padding: 20px;
        background-color: #1d1d1d;
        text-align: center;
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .form-style {
        align-items: center;
        justify-self: center;

        h1 {
            padding: 2vh;
            font-size: xx-large;
        }

        h2 {
            padding-bottom: 1vh;
        }

        h3 {
            padding-bottom: 1vh;
        }

        div {
            display: block;
        }

    }

    .form-block {
        width: 100%;
        padding-bottom: 2vh;
    }

    .form-item {
        width: 100%;
        padding-bottom: 1vh;

        input {
            min-width: 100%;
            background-color: #1d1d1d;
            color: #fff;
            height: 5vh;
            position: relative;
            border-bottom: 1px solid #fff;
        }


        input:hover {
            border: 3px solid $primary;
        }

        input:focus {
            border: 3px solid $primary;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type="datetime-local"]:after {
            content: "\25BC";
            color: #555;
            padding: 0 5px;
            display: none;
        }

        input[type="datetime-local"]::-webkit-calendar-picker-indicator {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;
            height: auto;
            color: transparent;
            background: transparent;
        }

        input[type="datetime-local"]::-webkit-inner-spin-button {
            z-index: 1;
        }

        input[type="datetime-local"]::-webkit-clear-button {
            z-index: 1;
        }
    }

    input[type="submit"] {
        background: none;
    }

    .form-button {
        width: 100%;
        height: 5vh;
        font-weight: 500;
        color: $primary;
        border: 2px solid $primary;
        padding: $spacing-sm $spacing-lg;
        border-radius: $spacing-sm / 2;
        cursor: pointer;
        background-color: 0 0 0 0.9;

        box-shadow: 0 0 0.5em 0 $primary;

        &:active {
            transform: scale(0.98);
            border-radius: 0;
        }

        &:hover {
            box-shadow: 0 0 0.75em 0 $primary;
        }
    }

    // form
}