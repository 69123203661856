@import "./variables.scss";


.header {
  height: $header-height;
  color: $text2;
  position: fixed;
  z-index: 1000;
  width: 100%;
  box-shadow: rgb(190, 113, 214 / 1) 0px 0px 20px 6px;

  &__content {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 $spacing-md;

    @include breakpoint(md) {
      padding: 0 $spacing-lg;
    }

    transition: 0.3s ease padding;

    height: 100%;
    display: flex;
    align-items: center;

    &>div {
      flex: 1;
      white-space: nowrap;

      &:nth-child(2) {
        display: flex;
        justify-content: center;
        height: 100%;
      }

      &:last-of-type {
        display: flex;
        justify-content: flex-end;
        flex: 1; //set 1 to center | set 0 to float right
      }
    }
  }

  &__button__container {
    display: none;

    @include breakpoint(md) {
      display: initial;
    }
  }

  &__toggler {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include breakpoint(md) {
      display: none;
    }

    &>svg {
      display: block;
      font-size: 32px;
    }
  }
}

.logo {
  font-size: 26px;
  font-weight: 700;
  color: $text2;
  transition: 0.3s ease color;

  &:hover {
    font-size: xx-large;
  }
}

.nav {
  width: 100%;
  position: fixed;
  top: $header-height;
  right: -100%;
  background: rgba(#1d1d1d, 0.8);
  text-align: center;
  padding: $spacing-md 0;

  &--open {
    transition: 0.2s ease right;
    right: 0;
  }

  &__item {
    display: block;
    padding: $spacing-md;

    text-decoration: none;
    color: inherit;

    height: 100%;

    transition: 0.3s ease color;

    &:hover {
      font-size: x-large;
    }
  }

  @include breakpoint(md) {
    position: static;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    background: initial;
    width: auto;
    padding: 0;

    &__item {
      padding: 0 $spacing-lg;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__button__container {
    @include breakpoint(md) {
      display: none;
    }

    padding: $spacing-md;
  }
}

.button {
  font-weight: 500;
  color: $primary;
  border: 2px solid $primary;
  padding: $spacing-sm $spacing-lg;
  border-radius: $spacing-sm / 2;
  cursor: pointer;

  box-shadow: 0 0 0.5em 0 $primary;

  &:active {
    transform: scale(0.98);
    border-radius: 0;
  }

  &:hover {
    box-shadow: 0 0 0.75em 0 $primary;
  }
}


//popups




.loginform__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent overlay */
  display: flex;
  justify-content: center;
  /* center horizontally */
  align-items: center;
  /* center vertically */
}

.loginform__container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 30px rgba($primary, 0.5);
  display: flex;
  flex-direction: column;
  /* align items vertically */
  justify-content: center;
  /* center items horizontally */
}

.form__group {
  margin-bottom: 15px;
}

label {
  display: block;
  /* each label on its own line */
  margin-bottom: 5px;
  /* space between label and input field */
}

/* Styling for buttons */
.loginform__container button,
.loginform__container .apple-login-button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  /* Make the button full width */
  margin-bottom: 10px;
  /* Add some space between buttons */
  background-color: #000;
  /* Adjust background color to match */
  color: #fff;
  /* Adjust text color to match */
  border: none;
  /* Remove border */
}


.loginform__container .apple-login-button:hover {
  background-color: #333;
  /* Adjust hover background color */
}


.loginform__container button[type="submit"] {
  background-color: #007bff;
  /* Blue background color for Login button */
  color: white;
}

.loginform__container button[type="button"] {
  background-color: #dc3545;
  /* Red background color for Cancel button */
  color: white;
}